.theme-bar-movie {
  display: block;
  width: 30px;
  height: 8px;
  float: left;
  margin: 1px;
}

.theme-bar-movie-active {
  display: block;
  width: 30px;
  height: 8px;
  float: left;
  margin: 1px;
}

.current-rating-movie {
  clear: both;
  width: 200px;
  text-align: center;
  font-weight: 600;
  display: block;
  padding: 0.5em 0;
}
.current-rating-movie-update {
  clear: both;
  width: 200px;
  text-align: center;
  font-weight: 600;
  display: block;
  padding: 0.5em 0;
}

.icon {
  vertical-align: center !important;
}
